import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

// Components
import HomepageHero from "../molecules/homepage/HomepageHero";
import HomepageUpdatesAndClients from "../molecules/homepage/HomepageUpdatesAndClients";
import HomepageAboutSlider from "../molecules/homepage/homepageAboutSlider";
import HomepageSolutions from "../molecules/homepage/homepageSolutions";
import HomepageWorkWith from "../molecules/homepage/homepageWorkWith";
import Insights from "../molecules/Insights";
// import HomepageCareers from "../molecules/homepage/HomepageCareers";
import ContactForm from "../molecules/ContactForm";
import { useStaticQuery, graphql } from "gatsby";
import AboutUsTestimonial from "../molecules/aboutus/AboutUsTestimonial";
// import AboutUsTestimonialNew from "../molecules/aboutus/AboutUsTestimonialNew";
import HomepageBrandingVideo from "../molecules/homepage/brandingVideo";
import HomepageCareersk from "../molecules/homepage/HomepageCareers/indexk";
import SliderCarousel from "../molecules/homepage/HomepageCareers/slider";
import { Fade } from "react-reveal";
import HeaderWithVideo from "../molecules/HeaderWithVideo";

// Components

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query getInsightsData {
      gcms {
        lifeAtGokens {
          video {
            url
          }
          videoThumbnail {
            url
          }
        }
        homePageInsights {
          insightPages {
            title
            shortDescription
            slug
            image {
              url
            }
          }
          showBanner
          bannerUrl
        }
        aboutUses {
          testimonialCards {
            companyName
            designation
            logoImage {
              url
            }
            paragraph
          }
        }
        insightPages(orderBy: createdAt_DESC, first: 10) {
          title
          shortDescription
          slug
          image {
            url
          }
          thumbnail {
            url
          }
        }
      }
    }
  `);

  console.log("Insights Data on Homepage is ", data);

  return (
    <Layout
      isNavWhite={true}
      isHomepage={true}
      showContactUsFooter={true}
      openAnswer={true}
    >
      <SEO
        canonicalLink={"https://www.goken-global.com/"}
        title="Home: Goken - Time To Innovate"
        description={
          "Goken Global provides innovative engineering and technology solutions, driving productivity and excellence."
        }
        facebookTitle={"Goken: Time To Innovate  | Home"}
        facebookDescription={
          "Discover how Goken leads in sustainable innovation. Explore our cutting-edge approaches to global challenges. Join our journey towards a better tomorrow."
        }
        keywords={`engineering solutions, staffing solutions, talent management, Training & Process improvement , productivity, technology advisory, accelerate development`}
      />

      <HomepageHero />

      <HomepageUpdatesAndClients />

      {/* <HomepageBrandingVideo></HomepageBrandingVideo> */}
      <Fade duration={1000}>
        <HomepageAboutSlider />
      </Fade>
      <Fade duration={1000}>
        <HomepageSolutions />
      </Fade>
      <Fade duration={1000}>
        <AboutUsTestimonial
          isHomepage={true}
          testimonialSlides={data.gcms.aboutUses[0].testimonialCards}
        />
      </Fade>
      {/* <AboutUsTestimonialNew
        isHomepage={true}
        testimonialSlides={data.gcms.aboutUses[0].testimonialCards}
      ></AboutUsTestimonialNew> */}
      <Fade duration={1000}>
        <HomepageWorkWith></HomepageWorkWith>
      </Fade>
      <div className="w-90-wrapper">
        <Fade duration={1000}>
          <Insights
            insights={data.gcms.insightPages}
            showBanner={data.gcms.homePageInsights[0].showBanner}
            marginFromTop={"m-top-40"}
            showTitle={true}
            bannerUrl={data.gcms.homePageInsights[0].bannerUrl}
            homepage={true}
            thumbnails={true}
          ></Insights>
        </Fade>
      </div>
      {/* <HomepageCareers></HomepageCareers> */}
      {/* <HomepageCareersk></HomepageCareersk> */}
      <Fade duration={1000}>
        <div className="lifeat-goken-section">
          <HeaderWithVideo
            // headingOne="Life At Goken"
            headingTwo={"Life At Goken"}
            btnTxt="Explore"
            para={`Our associates are like family, and we treat them with the same care and respect. We embrace diversity, celebrate each other's successes, and encourage one another. ​`}
            video={data.gcms.lifeAtGokens[0].video}
            url="/experiencelifeatgoken/"
            border={false}
          />
        </div>
        <SliderCarousel />
      </Fade>
      {/* <Fade duration={1000}>
        <ContactForm
          showForm={false}
          showMap={false}
          showDetails={true}
        ></ContactForm>
      </Fade> */}
    </Layout>
  );
};

export default IndexPage;
