import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Clients logo
import BytonLogo from "../../../components/atoms/icons/bytonLogo";
import BasfLogo from "../../../components/atoms/icons/basfLogo";
import TfgIcon from "../../../components/atoms/icons/TfgLogo";
import ContienentaLogo from "../../../components/atoms/icons/ContienentaLogo";
import KasaiLogo from "../../../components/atoms/icons/KasaiLogo";
import SumitomoLogo from "../../../components/atoms/icons/SumitomoLogo";
import NeatonLogo from "../../../components/atoms/icons/NeatonLogo";
import TsTechLogo from "../../../components/atoms/icons/TsTechLogo";
import NBHXLogo from "../../../components/atoms/icons/NBHXLogo";
import LPLogo from "../../../components/atoms/icons/L&PLogo";
import EGLogo from "../../../components/atoms/icons/EGLogo";
import UnipressLogo from "../../../components/atoms/icons/UnipressLogo";
import LogoTicker from "../../LogoTicker";
import "pure-react-carousel/dist/react-carousel.es.css";

import { useLanguage } from "../../../languageContext";
import Carousel from "react-multi-carousel";
import ImageBanner from "../../../components/atoms/banner/imageBanner";
import WipeBtn from "../../../components/atoms/buttons/WipeBtn";
import LogoMarquee from "./LogoMarquee";

// import BasfLogoImg from "../../../images/company/BASF-Logo_bw.svg";
// import TfgIconImg from "../../../images/company/TFG BLUE.png";
// import ContinentalImg from "../../../images/company/Continental.svg";
// import KASAIImg from "../../../images/company/KASAI.png";
// import SumitomoImg from "../../../images/company/Sumitomo_Heavy_Industries_logo.svg";
// import NEATONImg from "../../../images/company/NEATON.png";
// import tstechmainImg from "../../../images/company/tstechmain.png";
// import NBHXImg from "../../../images/company/NBHX.png";
// import L_PImg from "../../../images/company/L&P.png";
// import EGImg from "../../../images/company/EG.png";
// import UNIPRESImg from "../../../images/company/UNIPRES.png";
// import BytonImg from "../../../images/company/Byton_company_logo.svg";
/*
<BasfLogo></BasfLogo>
                  <TfgIcon></TfgIcon>
                  <ContienentaLogo></ContienentaLogo>
                  <KasaiLogo></KasaiLogo>
                  <SumitomoLogo></SumitomoLogo>
                  <NeatonLogo></NeatonLogo>
                  <TsTechLogo></TsTechLogo>
                  <NBHXLogo></NBHXLogo>
                  <LPLogo></LPLogo>
                  <EGLogo></EGLogo>
                  <UnipressLogo></UnipressLogo>
                  <BytonLogo></BytonLogo>
*/
const HomepageUpdatesAndClients = () => {
  const currentLanguage = useLanguage();
  const data = useStaticQuery(graphql`
    query GetCEOMessage {
      gcms {
        homepageLatestUpdateMessages {
          id
          paragraph
          heading
          showOnWebsite
          link
        }
      }
    }
  `);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 10000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 668, min: 0 },
      items: 3,
    },
  };
  return (
    <div className="update-section ">
      {data && (
        <>
          <div className="wrapper">
            {data.gcms.homepageLatestUpdateMessages[0].showOnWebsite ? (
              <div className="latest-message-wrapper  hover-update-in ">
                <div className="latest-message-clipped">
                  <AnchorLink
                    to={
                      data.gcms.homepageLatestUpdateMessages[0].link
                        ? data.gcms.homepageLatestUpdateMessages[0].link
                        : "/aboutus#founder"
                    }
                  >
                    <div className="latest-update-shadow">
                      <div className={`latest-update-wrapper`}>
                        <div className="latest-update-bg"></div>
                        <h2 className="heading title">
                          {data.gcms.homepageLatestUpdateMessages[0].heading}
                        </h2>
                        <p className="para">
                          {data.gcms.homepageLatestUpdateMessages[0].paragraph}
                        </p>
                      </div>
                    </div>
                  </AnchorLink>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="clients-wrapper">
              <h2 className="title uppercase-text w-90-wrapper">
                A few of Our Clients
              </h2>
              {/* <div className="clients-carousel-wrapper">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  responsive={responsive}
                  arrows={false}
                  customTransition={"transform 0.8s"}
                  autoPlay={true}
                  autoPlaySpeed={800}
                  slidesToSlide={1}
                  // showDots={true}
                  infinite={true}
                >
                  <div className="logo-carousel-wrapper">
                    <BasfLogo></BasfLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <TfgIcon></TfgIcon>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <ContienentaLogo></ContienentaLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <KasaiLogo></KasaiLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <SumitomoLogo></SumitomoLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <NeatonLogo></NeatonLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <TsTechLogo></TsTechLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <NBHXLogo></NBHXLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <LPLogo></LPLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <EGLogo></EGLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <UnipressLogo></UnipressLogo>
                  </div>
                  <div className="logo-carousel-wrapper">
                    <BytonLogo></BytonLogo>
                  </div>
                </Carousel>
              </div> */}
              {/* <LogoTicker> */}
              {/* <div className="logo-wrapper"> */}
              {/* <img src={BasfLogoImg} alt="" />
                <img src={TfgIconImg} alt="" />
                <img src={ContinentalImg} alt="" />
                <img src={KASAIImg} alt="" />
                <img src={SumitomoImg} alt="" />
                <img src={NEATONImg} alt="" />
                <img src={tstechmainImg} alt="" />
                <img src={NBHXImg} alt="" />
                <img src={L_PImg} alt="" />
                <img src={EGImg} alt="" />
                <img src={UNIPRESImg} alt="" />
                <img src={BytonImg} alt="" /> */}
              {/* </div> */}
              {/* </LogoTicker> */}
              {/* <LogoMarquee>
                <img src={BasfLogoImg} alt="" />
                <img src={TfgIconImg} alt="" />
                <img src={ContinentalImg} alt="" />
                <img src={KASAIImg} alt="" />
                <img src={SumitomoImg} alt="" />
                <img src={NEATONImg} alt="" />
                <img src={tstechmainImg} alt="" />
                <img src={NBHXImg} alt="" />
                <img src={L_PImg} alt="" />
                <img src={EGImg} alt="" />
                <img src={UNIPRESImg} alt="" />
                <img src={BytonImg} alt="" />
              </LogoMarquee> */}
              <LogoTicker>
                <div className="logo-wrapper">
                  <BasfLogo></BasfLogo>
                  <TfgIcon></TfgIcon>
                  <ContienentaLogo></ContienentaLogo>
                  <KasaiLogo></KasaiLogo>
                  <SumitomoLogo></SumitomoLogo>
                  <NeatonLogo></NeatonLogo>
                  <TsTechLogo></TsTechLogo>
                  <NBHXLogo></NBHXLogo>
                  <LPLogo></LPLogo>
                  <EGLogo></EGLogo>
                  <UnipressLogo></UnipressLogo>
                  <BytonLogo></BytonLogo>
                </div>
              </LogoTicker>
            </div>
          </div>
          <div className="mobile-wrapper">
            {data.gcms.homepageLatestUpdateMessages[0].showOnWebsite ? (
              <div className="latest-message-wrapper ">
                <div className="latest-message-clipped">
                  <AnchorLink
                    to={
                      data.gcms.homepageLatestUpdateMessages[0].link
                        ? data.gcms.homepageLatestUpdateMessages[0].link
                        : "/aboutus#founder"
                    }
                  >
                    <div className="latest-update-shadow">
                      <div
                        className={`latest-update-wrapper hover-update-${
                          currentLanguage ? currentLanguage : "en"
                        }`}
                      >
                        <div className="latest-update-bg"></div>
                        <h2 className="heading">
                          {data.gcms.homepageLatestUpdateMessages[0].heading}
                        </h2>
                        <p className="para">
                          {data.gcms.homepageLatestUpdateMessages[0].paragraph}
                        </p>
                      </div>
                    </div>
                  </AnchorLink>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="clients-wrapper">
              <h2 className="heading text-center">A few of Our Clients</h2>
              <LogoTicker>
                <div className="logo-wrapper">
                  <BasfLogo></BasfLogo>
                  <TfgIcon></TfgIcon>
                  <ContienentaLogo></ContienentaLogo>
                  <KasaiLogo></KasaiLogo>
                  <SumitomoLogo></SumitomoLogo>
                  <NeatonLogo></NeatonLogo>
                  <TsTechLogo></TsTechLogo>
                  <NBHXLogo></NBHXLogo>
                  <LPLogo></LPLogo>
                  <EGLogo></EGLogo>
                  <UnipressLogo></UnipressLogo>
                  <BytonLogo></BytonLogo>
                  {/* <img src={BasfLogoImg} alt="" />
                <img src={TfgIconImg} alt="" />
                <img src={ContinentalImg} alt="" />
                <img src={KASAIImg} alt="" />
                <img src={SumitomoImg} alt="" />
                <img src={NEATONImg} alt="" />
                <img src={tstechmainImg} alt="" />
                <img src={NBHXImg} alt="" />
                <img src={L_PImg} alt="" />
                <img src={EGImg} alt="" />
                <img src={UNIPRESImg} alt="" />
                <img src={BytonImg} alt="" /> */}
                </div>
              </LogoTicker>
              {/* <LogoMarquee>
                <img src={BasfLogoImg} alt="" />
                <img src={TfgIconImg} alt="" />
                <img src={ContinentalImg} alt="" />
                <img src={KASAIImg} alt="" />
                <img src={SumitomoImg} alt="" />
                <img src={NEATONImg} alt="" />
                <img src={tstechmainImg} alt="" />
                <img src={NBHXImg} alt="" />
                <img src={L_PImg} alt="" />
                <img src={EGImg} alt="" />
                <img src={UNIPRESImg} alt="" />
                <img src={BytonImg} alt="" />
              </LogoMarquee> */}
            </div>
          </div>
          <div className="clients-cta">
            <h2 className="title purple-heading">
              INTERESTED TO WORK WITH US?
            </h2>
            <WipeBtn
              className={"green-border green-btn bg-green-btn "}
              text={"Reach Out"}
              url="/contactus/"
              // link="/contactus/"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HomepageUpdatesAndClients;
