import React, { useState } from "react";

// Images
import ImageOne from "../../../images/homepageassets/about_engineering.jpg";
import ImageTwo from "../../../images/homepageassets/about_staffing.jpg";
import ImageThree from "../../../images/homepageassets/about_knowledge.jpg";
import ImageFour from "../../../images/homepageassets/about_validation.jpg";
import ImageFive from "../../../images/homepageassets/about_automation.jpg";
import ImageSix from "../../../images/homepageassets/about_lang.jpg";

import Zoom from "react-reveal/Zoom";
import WipeBtn from "../../../components/atoms/buttons/WipeBtn";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import leftArr from "../../../images/pink-left-blank.png";
import rightArr from "../../../images/pink-right-filled.png";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import CustomButtons from "./CustomButtons";
import AboutUsSlide from "../../../components/atoms/homepage/AboutUsSlide";
const HomepageAboutSlider = () => {
  const [index, setIndex] = useState(9);
  // const [showSlideTwo, setShowSlideTwo] = useState(false);
  // const [showSlideThree, setShowSlideThree] = useState(false);
  // const [showSlideFour, setShowSlideFour] = useState(false);
  // const [showSlideFive, setShowSlideFive] = useState(false);
  // const [showSlideSix, setShowSlidesix] = useState(false);
  const responsive = {
    large: {
      breakpoint: { min: 3001 },
      items: 1,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  // const changeSlideNext = () => {
  //   if (showSlideOne) {
  //     changeToSlideTwo();
  //   } else if (showSlideTwo) {
  //     changeToSlideThree();
  //   } else if (showSlideThree) {
  //     changeToSlideFour();
  //   } else if (showSlideFour) {
  //     changeToSlideFive();
  //   } else if (showSlideFive) {
  //     changeToSlideSix();
  //   } else if (showSlideSix) {
  //     changeToSlideOne();
  //   }
  // };
  // const changeSlidePrev = () => {
  //   if (showSlideOne) {
  //     changeToSlideSix();
  //   } else if (showSlideTwo) {
  //     changeToSlideOne();
  //   } else if (showSlideThree) {
  //     changeToSlideTwo();
  //   } else if (showSlideFour) {
  //     changeToSlideThree();
  //   } else if (showSlideFive) {
  //     changeToSlideFour();
  //   } else if (showSlideSix) {
  //     changeToSlideFive();
  //   }
  // };

  // const changeToSlideOne = () => {
  //   setShowSlideTwo(false);
  //   setShowSlideThree(false);
  //   setShowSlideFour(false);
  //   setShowSlideFive(false);
  //   setShowSlidesix(false);
  //   setShowSlideOne(true);
  // };

  // const changeToSlideTwo = () => {
  //   setShowSlideOne(false);
  //   setShowSlideThree(false);
  //   setShowSlideFour(false);
  //   setShowSlideFive(false);
  //   setShowSlidesix(false);
  //   setShowSlideTwo(true);
  // };

  // const changeToSlideThree = () => {
  //   setShowSlideOne(false);
  //   setShowSlideTwo(false);
  //   setShowSlideFour(false);
  //   setShowSlideFive(false);
  //   setShowSlidesix(false);
  //   setShowSlideThree(true);
  // };
  // const changeToSlideFour = () => {
  //   setShowSlideOne(false);
  //   setShowSlideTwo(false);
  //   setShowSlideFive(false);
  //   setShowSlidesix(false);
  //   setShowSlideThree(false);
  //   setShowSlideFour(true);
  // };
  // const changeToSlideFive = () => {
  //   setShowSlideOne(false);
  //   setShowSlideTwo(false);

  //   setShowSlidesix(false);
  //   setShowSlideThree(false);
  //   setShowSlideFour(false);
  //   setShowSlideFive(true);
  // };
  // const changeToSlideSix = () => {
  //   setShowSlideOne(false);
  //   setShowSlideTwo(false);
  //   setShowSlideThree(false);
  //   setShowSlideFour(false);
  //   setShowSlideFive(false);
  //   setShowSlidesix(true);
  // };
  function doSpeicalThing(...args) {
    console.log(args);
  }
  const slideData = [
    {
      heading: "ENGINEERING DESIGN",
      image: ImageOne,
      link: "/services/engineeringdesign/",
    },
    {
      heading: "STAFFING",
      image: ImageTwo,
      link: "/staffing/",
    },
    {
      heading: "KNOWLEDGE MANAGEMENT",
      image: ImageThree,
      link: "/services/knowledgesolution/",
    },
    {
      heading: "VALIDATION",
      image: ImageFour,
      link: "/services/validation/",
    },
    {
      heading: "AUTOMATION",
      image: ImageFive,
      link: "/services/automation/",
    },
    {
      heading: "LANGUAGE SERVICES",
      image: ImageSix,
      link: "/services/languageservices/",
    },
  ];

  const sliderItems =
    slideData.length > 0 &&
    slideData.map(({ heading, image, link }, index1) => {
      return (
        <AboutUsSlide
          duration={500}
          delay={550}
          itemIndex={index1}
          index={index}
          changeIndex={index1 !== 0 ? index1 + 3 : 9}
          heading={heading}
          image={image}
          // image={image}
          link={link}
        />
      );
    });
  const CustomDots = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems =
      slideData.length > 0 &&
      slideData.forEach(() => {
        return <button></button>;
      });
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <button
        className={`slider-dots bg-purple ${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    );
  };
  return (
    <div className="homepage-about">
      <div className="container-center w-90-wrapper">
        <div className="homepageabout-wrapper">
          <div className="homepage-about-slider-wrapper slider-wrapper">
            <Carousel
              arrows={false}
              renderButtonGroupOutside={true}
              customTransition="500ms ease-out"
              customButtonGroup={
                <CustomButtons leftArr={leftArr} rightArr={rightArr} />
              }
              // customDot={<CustomDots />}
              // renderDotsOutside={true}
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={3000}
              // showDots={true}
              responsive={responsive}
              beforeChange={(slide, { ...args }) => {
                console.log(slide + 1);
                setIndex(slide + 1);
              }}
            >
              {sliderItems}
              {/* <Fade left duration={500} delay={550} opposite when={index === 4}>
                <div className="slides-wrapper">
                  {" "}
                  <h1 className="heading">02. STAFFING</h1>
                  <Link to={"/staffing"}>
                    <div>
                      <img src={ImageTwo} alt="staffing"></img>
                    </div>
                  </Link>
                </div>
              </Fade>
              <Fade left duration={500} delay={550} opposite when={index === 5}>
                {" "}
                <div className="slides-wrapper">
                  {" "}
                  <h1 className="heading">03. KNOWLEDGE MANAGEMENT</h1>
                  <Link to={"/services/knowledgesolution"}>
                    <div className="slide">
                      <img src={ImageThree} alt="Desing Engineering"></img>
                    </div>
                  </Link>
                </div>
              </Fade>
              <Fade left duration={500} delay={550} opposite when={index === 6}>
                <div className="slides-wrapper">
                  <h1 className="heading">04. Validation</h1>

                  <Link to={"/services/validation"}>
                    <div className="slide">
                      <img src={ImageFour} alt="Desing Engineering"></img>
                    </div>
                  </Link>
                </div>
              </Fade>
              <Fade left duration={500} delay={550} opposite when={index === 7}>
                {" "}
                <div className="slides-wrapper">
                  <h1 className="heading">05. AUTOMATION</h1>

                  <Link to={"/services/automation"}>
                    <div className="slide">
                      <img src={ImageFive} alt="Desing Engineering"></img>
                    </div>
                  </Link>
                </div>
              </Fade>
              <Fade left duration={500} delay={550} opposite when={index === 8}>
                <div className="slides-wrapper">
                  {" "}
                  <h1 className="heading">06. LANGUAGE SERVICES</h1>
                  <Link to={"/services/languageservices"}>
                    <div className="slide">
                      <img src={ImageSix} alt="LANGUAGE SERVICES"></img>
                    </div>
                  </Link>
                </div>
              </Fade> */}
            </Carousel>
          </div>

          {/* <Fade right duration={500} delay={300} cascade> */}
          <div className="about-wrapper">
            <h2 className="tagline common-bottom-space">About Us</h2>
            <h3 className="heading common-bottom-space">WHO IS GOKEN ?</h3>
            <p className="para common-bottom-space">
              We are an engineering and staffing solutions provider fueled by
              exceptional talent who partners with companies to solve problems,
              accelerate development cycles, and build better products. We
              provide companies with the gift of time by uncovering the root
              causes of inefficient processes, giving them the space to envision
              their future innovations and shape industry trends.
            </p>

            {/* <button className="btn">Read More About Us</button> */}

            <WipeBtn
              className={"read-more-abt-us bg-purple-btn"}
              text="Learn More"
              url="/aboutus"
            />
          </div>
          {/* </Fade> */}
        </div>
      </div>
      {/* <div className="w-90-wrapper ">
        <h3 className="heading purple-text">
          What Our Experience working with multiple industries have taught us
        </h3>
        <p className="para ">
          {" "}
          In today's competitive business landscape, finding the{" "}
          <b>right engineering talent</b> can be a daunting task. Whether you're
          a <b>small startup or a large corporation</b>, it's crucial to have a
          team of skilled engineers to help drive your business forward. But
          with so many options out there,{" "}
          <b>
            how do you choose the right engineering services and staffing
            solutions for your specific needs?
          </b>
        </p>
      </div> */}
    </div>
  );
};

export default HomepageAboutSlider;
